import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import moment from "moment";
import CountUp from "react-countup";
import { Column } from "primereact/column";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBTypography,
  MDBIcon
} from "mdb-react-ui-kit";
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import "react-toastify/dist/ReactToastify.css";
export default function Test() {
  const { id } = useParams();
  //console.log('id:', id);

  const [userData, setUserData] = useState("");
  const toast = useRef(null);
  const [activeStatus, setActiveStatus] = useState(userData.active);
  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      token: localStorage.getItem("userToken")
    };
    axios.get(`https://api.insekten-scanner.com/api/dashboard/users/one_user/${id}`, { headers }).then(res => {
      // //console.log(res.data.data);
      setUserData(res.data.data);
      setActiveStatus(res.data.data.is_active);
      //console.log(res.data.data.is_active);
      // //console.log(userData.data);
      // //console.log(userData.all_points);
    });
  }, []);

  const toggleActiveStatus = () => {
    const newActiveStatus = activeStatus === 0 ? 1 : 0;
    setActiveStatus(newActiveStatus);
    //console.log(activeStatus)
    const headers = {
      token: localStorage.getItem("userToken"),
      "Content-Type": "application/json"
    };
    const requestBody = {
      user_id: userData.id,
      active: newActiveStatus
    };
    axios
      .post("https://api.insekten-scanner.com/api/dashboard/users/user_status", requestBody, { headers })
      .then(res => {
        //console.log(res.data);
        if (newActiveStatus === 1) {
          toast.current.show({ severity: "success", summary: "Success", detail: "User Unblocked Successfully", life: 3000 });
        } else {
          toast.current.show({ severity: "success", summary: "Success", detail: "User Blocked Successfully", life: 3000 });
        }
        userData();
        setUserData(prevProducts => {
          return prevProducts.map(product => {
            if (product.id === userData.id) {
              return {
                ...product,
                active: newActiveStatus
              };
            }
            return product;
          });
        });
      })

      .catch(error => {
       
      });
  };

  const ProductTableData = userData => {
    return <span>{userData?.name}</span>;
  };
  const handleBack = () => {
    navigate("/users");
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex flex-wrap gap-3">
        <Button label="Back" onClick={handleBack} icon="" severity="success" />
        <Button
          label={activeStatus == 0 ? "Unblock User" : "Block User"}
          icon={activeStatus == 0 ? "pi pi-check" : "pi pi-ban"}
          className={activeStatus == 0 ? " blocked-row1" : "unblocked-row1 "}
          onClick={toggleActiveStatus}
        />
      </div>
    );
  };

  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">User Profile</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" placeholder="Search..." />
      </span>
    </div>
  );
  return (
    <div className=" ">
      <Toast ref={toast} />

      <div className="d-flex justify-content-center  gap-3">
        {/* <div className='col-md-8 card'>
          <h1>{userData?.name}</h1>
          <DataTable
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" header={header}>
            <Column field="id" header="Product" style={{ minWidth: '12rem' }} ></Column>
            <Column  header="Points"  body={ProductTableData} style={{ minWidth: '12rem' }} ></Column>
            <Column field="id" header="Date" body={userData?.count} style={{ minWidth: '12rem' }} ></Column>

          </DataTable>
        </div> */}

        <div className=" px-3 profile-container">
          <MDBCard className="mb-4">
            {" "}
            <MDBCardBody className="text-center">
              <MDBCardImage src={userData?.image} alt="avatar" className="rounded-circle" style={{ width: "150px" }} fluid />
              <h3 className="text-muted mb-1 pt-3">{userData?.name} </h3>
              <MDBCardText className="text-muted mb-4">
                Email <span className="mx-2">|</span> <a href="#!">{userData?.email}</a>
              </MDBCardText>

              <hr className="mt-0 mb-4" />
              <MDBRow className="pt-1">
                <MDBCol size="6" className="mb-3">
                  <MDBTypography tag="h4">Phone</MDBTypography>
                  <MDBCardText className="text-muted">123 456 789</MDBCardText>
                </MDBCol>
                <MDBCol size="6" className="mb-3">
                  <MDBTypography tag="h4">Country</MDBTypography>
                  <MDBCardText className="text-muted">{userData?.country_name || "No data"}</MDBCardText>
                </MDBCol>
              </MDBRow>
              <hr className="mt-0 mb-4" />

              <div className=" count-data d-flex justify-content-center mb-2">
                <div className="d-flex justify-content-between text-center mt-5 mx-5 mb-2 gap-0  justify-content-around">
                  <div>
                    <CountUp start={0} end={userData?.suggested_count} duration={2} separator="," className="mb-1 h2" />
                    <MDBCardText className=" text-muted mb-0">Total Products</MDBCardText>
                  </div>
                  <div>
                    <MDBCardText className="mb-1 h2">{userData?.count_suggested_approved}</MDBCardText>
                    <MDBCardText className=" text-muted mb-0">Approved Products</MDBCardText>
                  </div>
                </div>
                <div className="d-flex justify-content-between text-center mt-5 mx-5 mb-2  justify-content-around">
                  <div className="">
                    <CountUp start={0} end={userData?.count_suggested_pending} duration={2} separator="," className="mb-1 h2" />
                    <MDBCardText className=" text-muted mb-0">Pending Products </MDBCardText>
                  </div>

                  <div>
                    <CountUp start={0} end={userData?.all_points} duration={2} separator="," className="mb-1 h2" />
                    <MDBCardText className="text-muted mb-0">Total Points</MDBCardText>
                  </div>
                </div>
              </div>
              <hr className="mt-0 mb-4" />

              <MDBCol size="12 " className="mb-3 justify-content-center align-items-center d-flex gap-3">
                <MDBTypography tag="h4">Created at:</MDBTypography>
                <MDBCardText className="text-muted h5">{moment(userData.created_at).format("DD-MM-YYYY")}</MDBCardText>
              </MDBCol>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
      <div className="profile-footer w-100">
        <Toolbar className="mb-4 tt  justify-content-center" left={leftToolbarTemplate} />
      </div>
    </div>
  );
}
