import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import Dropzone from "react-dropzone"
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

const ProductForm = () => {
  const [storesData, setStoresData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [insectsData, setinsectsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const fileToast = useRef(null);



  const [formData, setFormData] = useState({
    code: '',
    name_en: '',
    name_de: '',
    name_ar: '',
    generic_name_en: '',
    generic_name_de: '',
    generic_name_ar: '',
    ingredients_text_en: '',
    ingredients_text_de: '',
    ingredients_text_ar: '',
    quantity: '',
    safe: '',
    brands: [],
    stores: [],
    insects: [],
    categories: [],
    packaging_en: '',
    packaging_de: '',
    packaging_ar: '',
    allergens_en: '',
    allergens_de: '',
    allergens_ar: '',
    is_user: '8',
    ranking: null,
    images: []
  });


  // get the product details data 
  async function getProductsData() {
    try {
      const langChange = localStorage.getItem('langChange');
      const headers = {
        token: localStorage.getItem('userToken'),
        lang: langChange || 'en' // set default language to 'en' if langChange is not set
      };

      // add support for additional languages
      if (langChange === 'de') {
        headers.lang = 'de';
      } else if (langChange === 'ar') {
        headers.lang = 'ar';
      }

      const response = await axios.get('https://api.insekten-scanner.com/api/dashboard/products/product_data', { headers });
      const data = response.data;

      setStoresData(data.data.stores);
      setCategoriesData(data.data.categories);
      setinsectsData(data.data.insects);
      setBrandsData(data.data.brands);

    } catch (error) {
      console.error(error);
    }
  }


  const [status, setStatus] = useState('');

  useEffect(() => {
    getProductsData();

  }, []);


  const handleRankingChange = (e) => {
    const value = e.target.checked ? 1 : null;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ranking: value,
    }));
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Map the status value to its ID
    const statusIdMap = {
      undefined: 0,
      safe: 1,
      unsafe: 2
    };

    const updatedFormData = {
      ...formData,
      brands: formData.brands.map((brand) => brand.id),
      stores: formData.stores.map((store) => store.id),
      insects: formData.insects.map((insect) => insect.id),
      categories: formData.categories.map((category) => category.id),
      safe: statusIdMap[status], // Use the mapped status ID
      ranking: formData.ranking, // Include the updated ranking value
      images: formData.images.map((image) => image.id)
    };

    axios
      .post('https://api.insekten-scanner.com/api/dashboard/products/create', updatedFormData, {
        headers: {
          token: localStorage.getItem('userToken'),
          "Content-Type": "multipart/form-data"
        },
      })
      .then((res) => {
        alert('The Product Added successfully!');
        navigate('/products');
        //console.log(res);
      })
      .catch((err) => {
        // Failure
        alert('The Form Update failed :(');
        console.error(err);
      });
  };

  <Button onClick={() => handleSubmit()} className="p-button-rounded p-button-secondary" label="Submit" />


  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  // dropzone function 
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )
    setselectedFiles(files)
  }


  // Add a function to handle image updates
  const handleImageUpdate = (type) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageData = {
        type: type,
        image: reader.result,
      };
      // Update the formData with the new image data
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: prevFormData.images.map((image) =>
          image.type === type ? imageData : image
        ),
      }));
    };
    reader.readAsDataURL(file);
  };

  const onUpload = () => {
    fileToast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  return (
    <form onSubmit={handleSubmit} className='p-5 d-flex gap-4'>


      <div className='col-md-9'>


        <TabView className='card p-2 '>

          <TabPanel header="English" >
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="name_en">Name_EN:</label>
                <InputText
                  className="w-100"
                  name="name_en"
                  id="name_en"
                  onChange={e => setFormData({ ...formData, name_en: e.target.value })}
                  value={formData.name_en}
                />

              </div>
              <div className='w-100'>
                <label htmlFor="generic_name_en">Generic Name EN</label>
                <InputText className='w-100' name="generic_name_en"
                  id="generic_name_en"
                  onChange={e => setFormData({ ...formData, generic_name_en: e.target.value })}
                  value={formData.generic_name_en}
                />

              </div>
            </div>
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="allergens_en">Allergens</label>
                <InputText className='w-100' name="allergens_en"
                  id="allergens_en"
                  onChange={e => setFormData({ ...formData, allergens_en: e.target.value })}
                  value={formData.allergens_en}
                />
              </div>

              <div className='w-100'>
                <label htmlFor="packaging_en">packaging</label>
                <InputText className='w-100' name="packaging_en"
                  id="packaging_en"
                  onChange={e => setFormData({ ...formData, packaging_en: e.target.value })}
                  value={formData.packaging_en}
                />

              </div>
            </div>
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="ingredients_text_en">ingredients_text_en</label>
                <textarea className='w-100' name="ingredients_text_en"
                  id="ingredients_text_en"
                  onChange={e => setFormData({ ...formData, ingredients_text_en: e.target.value })}
                  value={formData.ingredients_text_en}
                />

              </div>
            </div>
          </TabPanel>


          <TabPanel header="German" >
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="name_en">Name_De:</label>
                <InputText
                  className="w-100"
                  name="name_de"
                  id="name_de"
                  onChange={e => setFormData({ ...formData, name_de: e.target.value })}
                  value={formData.name_de}
                />

              </div>
              <div className='w-100'>
                <label htmlFor="generic_name_de">Generic Name DE</label>
                <InputText className='w-100' name="generic_name_de"
                  id="generic_name_de"
                  onChange={e => setFormData({ ...formData, generic_name_de: e.target.value })}
                  value={formData.generic_name_de}
                />

              </div>
            </div>
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="allergens_de">Allergens</label>
                <InputText className='w-100' name="allergens_de"
                  id="allergens_de"
                  onChange={e => setFormData({ ...formData, allergens_de: e.target.value })}
                  value={formData.allergens_de}
                />
              </div>

              <div className='w-100'>
                <label htmlFor="packaging_de">packaging</label>
                <InputText className='w-100' name="packaging_de"
                  id="packaging_de"
                  onChange={e => setFormData({ ...formData, packaging_de: e.target.value })}
                  value={formData.packaging_de}
                />

              </div>
            </div>
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="ingredients_text_en">ingredients_text_de</label>
                <textarea className='w-100' name="ingredients_text_de"
                  id="ingredients_text_de"
                  onChange={e => setFormData({ ...formData, ingredients_text_de: e.target.value })}
                  value={formData.ingredients_text_de}
                />

              </div>
            </div>
          </TabPanel>



          <TabPanel header="Arabic" >
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="name_ar">Name_ar:</label>
                <InputText
                  className="w-100"
                  name="name_ar"
                  id="name_ar"
                  onChange={e => setFormData({ ...formData, name_ar: e.target.value })}
                  value={formData.name_ar}
                />

              </div>
              <div className='w-100'>
                <label htmlFor="generic_name_ar">Generic Name AR</label>
                <InputText className='w-100' name="generic_name_ar"
                  id="generic_name_ar"
                  onChange={e => setFormData({ ...formData, generic_name_ar: e.target.value })}
                  value={formData.generic_name_ar}
                />

              </div>
            </div>
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="allergens_ar">Allergens</label>
                <InputText className='w-100' name="allergens_ar"
                  id="allergens_ar"
                  onChange={e => setFormData({ ...formData, allergens_ar: e.target.value })}
                  value={formData.allergens_ar}
                />
              </div>

              <div className='w-100'>
                <label htmlFor="packaging_ar">packaging</label>
                <InputText className='w-100' name="packaging_ar"
                  id="packaging_ar"
                  onChange={e => setFormData({ ...formData, packaging_ar: e.target.value })}
                  value={formData.packaging_ar}
                />

              </div>
            </div>
            <div className='d-flex gap-4 mb-3'>
              <div className='w-100'>
                <label htmlFor="ingredients_text_ar">ingredients_text_ar</label>
                <textarea className='w-100' name="ingredients_text_ar"
                  id="ingredients_text_ar"
                  onChange={e => setFormData({ ...formData, ingredients_text_ar: e.target.value })}
                  value={formData.ingredients_text_ar}
                />

              </div>
            </div>
          </TabPanel>


        </TabView>


        <div className='d-flex gap-4 mb-3 mt-3'>
          <div className='w-100'>
            <label htmlFor="code">Product Code</label>
            <InputText className='w-100' name="code"
              id="code"
              onChange={e => setFormData({ ...formData, code: e.target.value })}
              value={formData.code}
            />

          </div>

          <div className='w-100'>
            <label htmlFor="categories">Categories</label>

            <MultiSelect
              optionLabel="name"
              filter
              placeholder="Select categories"
              display="chip"
              id="categories"
              value={formData.categories}
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              options={categoriesData}
              onChange={e => setFormData({ ...formData, categories: e.target.value })}
            />

          </div>

        </div>



        <div className='d-flex gap-4 mb-3'>
          <div className='w-100'>
            <label htmlFor="stores">Stores</label>
            <MultiSelect
              optionLabel="name"
              display="chip"
              filter
              placeholder="Select stores"
              id='stores'
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              options={storesData}
              value={formData.stores}
              onChange={e => setFormData({ ...formData, stores: e.target.value })}
            />


          </div>
          <div className='w-100'>
            <label htmlFor="stores">brands</label>
            <MultiSelect
              optionLabel="name"
              filter
              display="chip"
              placeholder="Select brands"
              id='brands'
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              options={brandsData}
              value={formData.brands}
              onChange={e => setFormData({ ...formData, brands: e.target.value })}

            />
          </div>
        </div>


        <div className='d-flex gap-4 mb-3'>

          <div className='w-100'>
            <label htmlFor="insects">Insects</label>
            <MultiSelect optionLabel="name" id='insects'
              value={formData.insects}
              onChange={e => setFormData({ ...formData, insects: e.target.value })}
              filter placeholder="Select Insects" maxSelectedLabels={3} className="w-100 md:w-20rem" display="chip" options={insectsData}
            />

          </div>

        </div>

        <div className='card p-4 my-4 pt-4 mb-4'>

          {/* first image */}
          <div className='d-flex justify-content-between mb-5'>
            <h4>Ingredients Image</h4>

            <div className="flex justify-content-center">
              <Toast ref={fileToast}></Toast>
              <FileUpload
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                maxFileSize={1000000}
                onUpload={onUpload}
              />
            </div>

            {/* Render the images from the backend */}
            {formData?.images?.map((image, index) => (
              image.type === 'more_interesting' && (
                <img width='150px' height='150px' key={index} src={image.image} alt={`Image ${index}`} />
              )
            ))}
          </div>

          {/* second image */}
          <div className='d-flex justify-content-between mb-5'>
            <h4>Nutrition Image</h4>

            <div className="flex justify-content-center">
              <Toast ref={fileToast}></Toast>
              <FileUpload
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                maxFileSize={1000000}
                onUpload={onUpload}
              // onChange={handleImageUpdate(image.type)}
              />
            </div>

            {/* Render the images from the backend */}
            {formData?.images?.map((image, index) => (
              image.type === 'nutrition_facts' && (
                <img
                  width='150px'
                  height='150px'
                  key={index}
                  src={image.image}
                />)
            ))}
          </div>
          {/* Third image  */}

          <div className='d-flex justify-content-between mb-5'>
            <h4>Nutrition Image</h4>

            <div className="flex justify-content-center">
              <Toast ref={fileToast}></Toast>
              <FileUpload
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                maxFileSize={1000000}
                onUpload={onUpload}
              />
            </div>

            {/* Render the images from the backend */}
            {formData?.images?.map((image, index) => (
              image.type === 'ingredients' && (
                <img width='150px' height='150px' key={index} src={image.image} alt={`Image ${index}`} />
              )
            ))}
          </div>
          {/* fourth image  */}

          <div className='d-flex  justify-content-between mb-5'>
            <h4>More Images</h4>

            <div className="  flex justify-content-center">
              <Toast ref={fileToast}></Toast>
              <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="image/*" maxFileSize={1000000} onUpload={onUpload} />
            </div>

          </div>
        </div>


        <div className='d-flex gap-3 justify-content-start '>


          <Button className="mt-4 d-flex justify-content-center align-items-center" type="submit" label="Add Product" icon="pi pi-check" />
          <Link to='/products'>
            <Button className="mt-4 d-flex justify-content-center align-items-center" type="submit" label="Cancel" />
          </Link>
        </div>
      </div>
      <div className='col-md-3 px-3 card right-card pt-5 '>

        <div className='product-status d-flex justify-content-around'>
          <input
            type="radio"
            className="btn-check"
            name="status"
            id="undefined-outlined"
            value="undefined"
            checked={status === 'undefined'}
            onChange={handleStatusChange}
            autoComplete="off"
          />
          <label className="btn btn-outline-warning status-label" htmlFor="undefined-outlined">Undefined</label>

          <input
            type="radio"
            className="btn-check"
            name="status"
            id="safe-outlined"
            value="safe"
            checked={status === 'safe'}
            onChange={handleStatusChange}
            autoComplete="off"
          />
          <label className="btn btn-outline-success status-label" htmlFor="safe-outlined">Safe</label>

          <input
            type="radio"
            className="btn-check"
            name="status"
            id="unsafe-outlined"
            value="unsafe"
            checked={status === 'unsafe'}
            onChange={handleStatusChange}
            autoComplete="off"
          />
          <label className="btn btn-outline-danger status-label" htmlFor="unsafe-outlined">Unsafe</label>
        </div>
        <div className="card p-3 pb-3 justify-content-between mt-3 d-flex flex-row align-items-center">

          <h6 className="ml-2">
            Top 10 products
          </h6>
          <Checkbox name="category" value={formData?.ranking}
            onChange={handleRankingChange}
            checked={formData?.ranking !== null}
          />
        </div>
        <div className='pt-3'>

          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone dz-clickable img-drop ">
                <div
                  className="dz-message img-drop needsclick d-flex justify-content-center gap-5 "
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3 text-center d-flex">
                    <i className="drop-cloud pi pi-cloud-upload text-muted display-4"></i>
                  </div>
                  <h4 className=' drop-txt text-center'> Main Image</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div>

            <div className="dropzone-previews d-flex justify-content-center h-100 mt-3 mb-4" id="file-previews">
              {formData?.images?.map((image, index) => (
                image.type === 'front' && (
                  <img height='250px' width='250px' key={index} src={image.image} alt={`Image ${index}`} />
                )
              ))}
            </div>

          </div>
        </div>




      </div>
    </form>
  );
};

export default ProductForm;