import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { TabView, TabPanel } from "primereact/tabview";
import { Checkbook, Island } from "mdi-material-ui";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";

const Keywords = () => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [roles, setRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]); // Initialize as an empty array
  const [newRoleName, setNewRoleName] = useState("");
  const [newNameAR, setNewNameAR] = useState("");
  const [newNameDE, setNewNameDE] = useState("");
  const [newKeyword, setNewKeyword] = useState("");
  const [newCode, setNewCode] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const toast = useRef(null);

  useEffect(
    () => {
      fetchInsects();
    },
    [currentPage, selectedLanguage]
  );

  const fetchInsects = async () => {
    try {
      const response = await axios.get(`https://api.insekten-scanner.com/api/dashboard/insects?page=${currentPage}`, {
        headers: {
          token: localStorage.getItem("userToken"),
          lang: selectedLanguage
        }
      });
      const { insects: fetchedInsects, total } = response.data.data;
      setRoles(fetchedInsects);
      //console.log(response.data.data);
      setTotalPages(Math.ceil(total / 10)); // Assuming 10 roles per page
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const createKeyword = async () => {
    try {
      const response = await axios.post(
        "https://api.insekten-scanner.com/api/dashboard/insects/create",
        {
          name_en: newRoleName,
          name_de: newNameDE,
          name_ar: newNameAR,
          keyword: newKeyword,
          code: newCode
        },
        {
          headers: {
            token: localStorage.getItem("userToken")
          }
        }
      );
      const newRole = response.data.data;
      setRoles([...roles, newRole]);
      toast.current.show({ severity: "success", summary: "Success", detail: "Created Successfully", life: 3000 });
      fetchInsects();
      hideDialog();
    } catch (error) {
      console.error("Error creating role:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add this keyword", life: 3000 });
    }
  };

  const handleEdit = (roleId, keyword, code, roleName, name_de, name_ar) => {
    setSelectedRole(roleId);
    setNewRoleName(roleName);
    setSelectedPermissions(permissions);
    setNewKeyword(keyword);
    setNewCode(code);
    setNewNameDE(name_de);
    setNewNameAR(name_ar);
  };

  const handleCancelEdit = () => {
    setSelectedRole(null);
    setNewRoleName("");
    setSelectedPermissions([]);
    setNewNameDE("");
    setNewNameAR("");
    setNewKeyword("");
    setNewCode("");
  };

  const handleSaveEdit = async () => {
    try {
      if (!selectedRole) {
        console.error("Selected role is missing.");
        return;
      }

      const response = await axios.post(
        "https://api.insekten-scanner.com/api/dashboard/insects/update",
        {
          id: selectedRole,
          keyword: newKeyword,
          name_en: newRoleName,
          name_de: newNameDE,
          name_ar: newNameAR,
          code: newCode
        },
        {
          headers: {
            token: localStorage.getItem("userToken"),
            lang: selectedLanguage
          }
        }
      );

      fetchInsects();
      //console.log('Insect update response:', response);
      toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully", life: 3000 });

      setSelectedRole(null);
      setNewRoleName("");
      setSelectedPermissions([]);
      setNewNameDE("");
      setNewNameAR("");
      setNewKeyword("");
      setNewCode("");
    } catch (error) {
      console.error("Error updating insect:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to Update", life: 3000 });
    }
  };

  async function searchKeywords(e, page) {
    if (e.target.value) {
      try {
        const token = localStorage.getItem("userToken");
        const lang = "en"; // Replace 'en' with the desired language value

        const response = await axios.get(
          `https://api.insekten-scanner.com/api/dashboard/tables_search/insects/${e.target.value}?page=${page}`,
          {
            headers: {
              token: token,
              lang: lang
            }
          }
        );

        const { data } = response;
        setRoles(data?.data?.insects);
        //console.log(data);
      } catch (error) {
        console.error("Error searching admins:", error);
      }
    } else {
      // Call the fetchAdmins function to reset the table
      fetchInsects();
    }
  }
  const handleDelete = async roleId => {
    const confirmDelete = window.confirm("Are you sure you want to delete this role?");
    if (confirmDelete) {
      try {
        await axios.delete("https://api.insekten-scanner.com/api/dashboard/insects/delete", {
          headers: {
            token: localStorage.getItem("userToken"),
            "Content-Type": "application/json"
          },
          data: {
            insect_id: roleId
          }
        });
        toast.current.show({ severity: "success", summary: "Success", detail: "Deleted Successfully", life: 3000 });
        fetchInsects();
      } catch (error) {
        console.error("Error deleting role:", error);
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete", life: 3000 });
      }
    }
  };
  const getLocalizedRoleName = role => {
    if (role.translations && role.translations.length > 0) {
      const translation = role.translations.find(translation => translation.locale === selectedLanguage);
      if (translation && translation.name) {
        return translation.name;
      }
    }
    return role.name;
  };

  const showDialog = () => {
    setNewRoleName("");
    setNewNameAR("");
    setNewNameDE("");
    setSelectedPermissions([]);
    setSelectedRole(null);
    setDisplayDialog(true);
  };

  const hideDialog = () => {
    setDisplayDialog(false);
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="card rounded-4 p-4">
        <div className="flex mb-2 d-flex px-2  bg-light flex-wrap gap-2 align-items-center justify-content-between">
          <Button
            dir={isLang == "en" ? "ltr" : "rtl"}
            label={isLang === "en" ? "Add Keyword" : "إضافة كلمة"}
            icon="pi pi-plus"
            className="p-button-success"
            onClick={showDialog}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              onChange={searchKeywords}
              type="search"
              onInput={e => searchKeywords(e?.target?.value)}
              placeholder={isLang === "en" ? "Search..." : "البحث"}
            />
          </span>
        </div>

        <table className="table pt-2">
          <thead className="table-light">
            <tr>
              <th scope="col">{isLang === "en" ? "ID" : "الرقم"}</th>
              <th scope="col">{isLang === "en" ? "Insect Name" : "اسم الحشرة"}</th>
              <th scope="col"> {isLang === "en" ? "Code" : "الكود "}</th>
              <th scope="col"> {isLang === "en" ? "Keyword" : "الكلمة"}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody className="text-center">
            {roles.map(role => (
              <tr className="role-tr" key={role.id}>
                <td>{role.id}</td>
                <td>{getLocalizedRoleName(role)}</td>
                <td>{role.code}</td>
                <td>{role.keyword}</td>
                <td>
                  <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    className="mr-2"
                    onClick={() => handleEdit(role.id, role.keyword, role.code, role.name, role.name_de, role.name_ar)}
                  />
                  <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleDelete(role.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div>
          {Array.from({ length: totalPages }).map((_, index) => (
            <button key={index} onClick={() => handlePageChange(index + 1)} disabled={index + 1 === currentPage}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {/* Dialog for creating a new role */}
      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={displayDialog}
        onHide={hideDialog}
        header={isLang === "en" ? "Add Keyword" : "إضافة كلمة"}
        style={{ width: "45rem", height: "480px" }}
      >
        <TabView dir={isLang == "en" ? "ltr" : "rtl"}>
          <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"}>
            <div className="d-flex gap-3">
              <label htmlFor="newRoleName" className="font-bold h5 d-flex w-25">
                {isLang === "en" ? "EN_Name" : "الاسم"}
              </label>
              <InputText
                id="newRoleName"
                value={newRoleName}
                onChange={e => setNewRoleName(e.target.value)}
                required
                className="w-100"
              />
            </div>
          </TabPanel>
          <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"}>
            <div className="d-flex gap-3">
              <label htmlFor="newNameDE" className="font-bold h5 d-flex w-25">
                {isLang === "en" ? "DE_Name" : "الاسم "}
              </label>
              <InputText
                id="newNameDE"
                value={newNameDE}
                onChange={e => setNewNameDE(e.target.value)}
                required
                className="w-100"
              />
            </div>
          </TabPanel>
          <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"}>
            <div className="d-flex gap-3">
              <label htmlFor="newNameAR" className="font-bold h5 d-flex w-25">
                {isLang === "en" ? "AR_Name" : " الاسم"}
              </label>
              <InputText
                id="newNameAR"
                value={newNameAR}
                onChange={e => setNewNameAR(e.target.value)}
                required
                className="w-100"
              />
            </div>
          </TabPanel>
        </TabView>

        <div className="p-col-4 d-flex gap-3 mb-4">
          <label className="font-bold h5 d-flex w-25"> {isLang === "en" ? "Keyword" : "الكلمة"} </label>
          <InputText className="w-100" value={newKeyword} onChange={e => setNewKeyword(e.target.value)} />
        </div>
        <div className="p-col-4 d-flex gap-3 mb-4">
          <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Code" : "الكود"}</label>
          <InputText className="w-100" value={newCode} onChange={e => setNewCode(e.target.value)} />
        </div>
        <div dir={isLang == "en" ? "ltr" : "rtl"} className="text-center">
          <Button
            dir={isLang == "en" ? "ltr" : "rtl"}
            label={isLang === "en" ? "Cancel" : "إلغاء"}
            icon="pi pi-times"
            className="p-button-text"
            onClick={hideDialog}
          />
          <Button
            dir={isLang == "en" ? "ltr" : "rtl"}
            label={isLang === "en" ? "Create" : "حفظ"}
            icon="pi pi-check"
            className="p-button-success"
            onClick={createKeyword}
          />
        </div>
      </Dialog>
      <Dialog
        visible={selectedRole !== null}
        onHide={handleCancelEdit}
        header="Edit Keyword"
        style={{ width: "45rem", height: "550px" }}
        footer={
          <div>
            <div className="text-center">
              <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={handleCancelEdit} />
              <Button label="Save" icon="pi pi-check" className="p-button-success" onClick={handleSaveEdit} />
            </div>
          </div>
        }
      >
        <div className="edit-content">
          <TabView>
            <TabPanel header="English">
              <div className="d-flex gap-3">
                <label htmlFor="newRoleName" className="font-bold h5 d-flex w-25">
                  Role Name:
                </label>
                <InputText
                  id="newRoleName"
                  value={newRoleName}
                  onChange={e => setNewRoleName(e.target.value)}
                  required
                  className="w-100"
                />
              </div>
            </TabPanel>
            <TabPanel header="German">
              <div className="d-flex gap-3">
                <label htmlFor="newNameDE" className="font-bold h5 d-flex w-25">
                  Name DE:
                </label>
                <InputText
                  id="newNameDE"
                  value={newNameDE}
                  onChange={e => setNewNameDE(e.target.value)}
                  required
                  className="w-100"
                />
              </div>
            </TabPanel>
            <TabPanel header="Arabic">
              <div className="d-flex gap-3">
                <label htmlFor="newNameAR" className="font-bold h5 d-flex w-25">
                  Name AR:
                </label>
                <InputText
                  id="newNameAR"
                  value={newNameAR}
                  onChange={e => setNewNameAR(e.target.value)}
                  required
                  className="w-100"
                />
              </div>
            </TabPanel>
          </TabView>
          <div className="p-col-4 d-flex gap-3 mb-4">
            <label className="font-bold h5 d-flex w-25">Keyword</label>
            <InputText className="w-100" value={newKeyword} onChange={e => setNewKeyword(e.target.value)} />
          </div>
          <div className="p-col-4 d-flex gap-3 mb-4">
            <label className="font-bold h5 d-flex w-25">Code</label>
            <InputText className="w-100" value={newCode} onChange={e => setNewCode(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Keywords;
