import React, { useContext, useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

import { VendersContext } from '../../context/Store';
import { Island } from 'mdi-material-ui';


const formatData = (usersData, productsData, products_scanned_chart) => {

    const labels = usersData.map(d => d.full_date);

    return {
        labels,
        datasets: [
            {
                label: 'Totla Users',
                data: usersData.map(d => d.users_count),
                backgroundColor: 'rgba(0, 123, 255, 0.5)',
                borderColor: 'rgba(0, 123, 255, 1)',
                fill: false
            },
            {
                label: 'Total Products',
                data: productsData.map(d => d.products_count),
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
                borderColor: 'rgba(255, 206, 86, 1)',
                fill: false
            },
            {
                label: 'Scanned Products',
                data: products_scanned_chart.map(d => d.scanned_count),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false
            }
        ]
    }
}


const LineChart = () => {
    let { isLang, setIsLang } = useContext(VendersContext);

    const [chartData, setChartData] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const defaultStartDate = new Date();
        defaultStartDate.setDate(1); // Set the date to the first day of the current month
        const defaultEndDate = new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth() + 1, 0); // Set the date to the last day of the current month
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);

        fetchData(defaultStartDate, defaultEndDate);
    }, []);

    const fetchData = async (start, end) => {
        if (!start || !end) {
            return;
        }

        const startDateStr = formatDate(start);
        const endDateStr = formatDate(end);

        try {
            const response = await fetch(`https://api.insekten-scanner.com/api/dashboard/statistics?start_date=${startDateStr}&end_date=${endDateStr}`, {
                headers: {
                    token: localStorage.getItem('userToken')
                }
            });
            const data = await response.json();
            setChartData(formatData(data.data.users_chart, data.data.products_chart, data.data.products_scanned_chart));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    };




    const handleSearch = () => {
        fetchData(startDate, endDate);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.value);
    };

    return (
        <div>
            <div dir={isLang == "en" ? 'ltr' : 'rtl'} className="card p-4 mb-3 d-flex flex-row justify-content-between">
                <div className="d-flex flex-row align-items-center gap-3">
                    <h6> {isLang === "en" ? "From:" : "من:"}</h6>
                    <Calendar dir={isLang == "en" ? 'ltr' : 'ltr'}
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Enter start date"
                        showIcon
                        iconPos="left"
                    />
                </div>
                <div className="d-flex flex-row align-items-center gap-3">
                    <h6> {isLang === "en" ? "To:" : "إلى:"}</h6>
                    <Calendar dir={isLang == "en" ? 'ltr' : 'ltr'}
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Enter end date"
                        showIcon
                        iconPos="left"
                    />
                </div>
                <Button label={isLang === "en" ? "Search" : "بحث"} onClick={handleSearch} />
            </div>
            <Chart dir={isLang == "en" ? 'ltr' : 'rtl'} type="line" data={chartData} />
        </div>
    );
};

export default LineChart;