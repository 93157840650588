import React, { useContext, useEffect, useRef, useState } from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';
import { VendersContext } from '../../context/Store';

export default function Profile() {
    let { isLang, setIsLang } = useContext(VendersContext);

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        email: '',
        image: '',
        phone: '',
        old_password: '',
        new_password: '',
    });


    const [isLoading, setIsLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get('https://api.insekten-scanner.com/api/dashboard/profile/get_profile', {
                    headers: {
                        token: token
                    }
                });
                setFormData(response?.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(
                'https://api.insekten-scanner.com/api/dashboard/profile/change_password', // Update the URL with the correct endpoint
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: localStorage.getItem('userToken') // Add the token header
                    },
                    body: JSON.stringify({
                        id: formData.id,
                        old_password: formData.old_password,
                        new_password: formData.new_password
                    })
                }
            );

            if (response.ok) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Password changed successfully!", life: 3000 });

                // Clear the form data
                setFormData({
                    id: '',
                    old_password: '',
                    new_password: ''
                });

                // Close the dialog
                setVisible(false);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Failed to change password", life: 3000 });
            }
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleUpdate = async (event) => {
        event.preventDefault();
        const { name, email, image } = formData;

        // Create a new FormData object
        const updatedFormData = new FormData();
        updatedFormData.append('name', name);
        updatedFormData.append('email', email);
        if (image) {
            // Validate the image file
            if (!validateImage(image)) {
                toast.current.show({ severity: "error", summary: "Error", detail: "Invalid file format. Please upload an image file with .jpg, .jpeg, .png, or .gif extension", life: 3000 });

                return;
            }
            updatedFormData.append('image', image);
        }

        try {
            const response = await axios.post('https://api.insekten-scanner.com/api/dashboard/profile/update_profile', updatedFormData, {
                headers: {
                    "Content-type": "multipart/form-data",

                    token: localStorage.getItem('userToken') // Add the token header
                }
            });
            toast.current.show({ severity: "success", summary: "Success", detail: "Profile updated successfully!", life: 3000 });

            // Show the uploaded image
            document.getElementById("image").src = URL.createObjectURL(image);
        } catch (error) {
            //console.log(error);
        }
    };

    const validateImage = (image) => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        return allowedExtensions.test(image.name);
    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Show the selected image
            setFormData({ ...formData, image: file });
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            // Show the current image
            setImagePreview(formData.image);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    } const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    return (
        <MDBContainer className="container py-5 h-100">
                  <Toast ref={toast} />

            <MDBRow className="justify-content-around align-items-center h-100">
                <MDBCol md="12" xl="12">
                    <MDBCard style={{ borderRadius: '15px' }}>
                        <MDBCardBody  className="text-center">
                            <div className=' d-flex gap-5 justify-content-between '>
                                <form onSubmit={handleUpdate} className="card-body">
                                <h5 className=' text-primary text-center'>{isLang === "en" ? "Personal Informations" : "البيانات الشخصية  "}</h5>
                                    <Divider />


                                    <div className=' d-flex flex-row gap-4 profile-inputs justify-content-between w-100'>
                                        <div  className='d-flex flex-column gap-4 w-100 justify-content-center'>

                                            <div className='d-flex gap-3 w-100'>
                                                <label htmlFor="profileName" className="font-bold h5 align-items-center d-flex ">
                                                    {isLang === "en" ? "   Name:" : "الاسم:"}
                                                </label>
                                                <InputText

                                                    className='w-100'
                                                    id="profileName"
                                                    name="name"
                                                    value={formData?.name}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div className='d-flex gap-3 w-100'>
                                                <label htmlFor="profileEmail" className="font-bold h5 align-items-center d-flex ">
                                                    {isLang === "en" ? "    Email:" : "الايميل:"}

                                                </label>
                                                <InputText
                                                    className='w-100 bg-light'
                                                    id="profileEmail"
                                                    onChange={handleChange}
                                                    disabled
                                                    value={formData?.email} tag="h4"
                                                />
                                            </div>
                                        </div>

                                        {/* image */}
                                        <div className="mt-3 border-3 w-25">


                                            <Image
                                                src={imagePreview || formData.image} fluid

                                                className='border mb-2'
                                                width='150px' preview />
                                            <div class="mb-3 w-100">
                                                <input class="form-control" type="file" id="image" name="image" onChange={handleImageChange} />
                                            </div>


                                        </div>
                                    </div>

                                    <button type="submit" className="text-center btn btn-primary">
                                        {isLang === "en" ? "  Update data" : "تعديل البيانات"}
                                    </button>
                                </form>
                                <div className="text-center pt-2">
                                </div>
                            </div>



                        </MDBCardBody>


                    </MDBCard>
                    <MDBCard className="mt-4" style={{ borderRadius: '15px' }}>


                        <MDBCardBody>
                            <div  className=' d-flex gap-5 justify-content-between '>
                                <form onSubmit={handlePasswordChange} className="card-body">
                                    <h5 className=' text-primary text-center'>{isLang === "en" ? "Change Password" : "تغيير كلمة السر"}</h5>
                                    <Divider />

                                    <div className='d-flex flex-column gap-4'>

                                        <div className='d-flex gap-3 w-100'>
                                            <label htmlFor="old_password" className="font-bold align-items-center h5 d-flex change-password-label">
                                                {isLang === "en" ? " Old password:" : " كلمة السر القديمة "}
                                            </label>
                                            <InputText className='w-100'
                                                id="old_password"
                                                value={formData.old_password}
                                                onChange={handleChange}
                                                type="password"


                                                tag="h4" />
                                        </div>
                                        <div className='d-flex gap-3 w-100'>
                                            <label htmlFor="new_password" className="font-bold align-items-center h5 d-flex change-password-label ">
                                                {isLang === "en" ? " New password:" : " كلمة السر الجديدة "}
                                            </label>
                                            <InputText className='w-100'
                                                type="password"
                                                id="new_password"
                                                name="new_password"
                                                value={formData.new_password}
                                                onChange={handleChange} />
                                        </div>

                                        <div className="text-center pt-2">
                                            <button type="submit" className="text-center btn btn-primary">
                                                {isLang === "en" ? "  Update Password" : "تعديل كلمة السر"}
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}
