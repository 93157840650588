import React, { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Link, useLocation } from "react-router-dom"
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';

import { Image } from 'primereact/image';
import { Island, Select } from 'mdi-material-ui';
import { VendersContext } from '../../context/Store';

const Products = (props) => {
  let { isLang, setIsLang } = useContext(VendersContext);


  const [products, setProducts] = useState([]);
  // const [insectsData, setInsectsData] = useState([]);
  const [selectedInsects, setSelectedInsects] = useState([]);

  const perPage = 10; 
  const [page, setPage] = useState(1);

  async function getProducts(page, insectIds = "") {
    try {
      const langChange = localStorage.getItem('langChange');
      const headers = {
        token: localStorage.getItem('userToken'),
        lang: langChange || 'en'
      };

      if (langChange === 'de') {
        headers.lang = 'de';
      } else if (langChange === 'ar') {
        headers.lang = 'ar';
      }

      let url = `https://api.insekten-scanner.com/api/dashboard/products?page=${page}&per_page=${perPage}`;

      if (insectIds) {
        url += `&insect=${insectIds}`;
      }

      const response = await axios.get(url, { headers });
      const data = response.data;

      setProducts(data.data.products);
      setTotalRecords(data.data.total);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.error(error);
      }    }
  }
  const handleInsectSelectionChange = (e) => {
    const selectedIds = e.value.map((insect) => insect.id);
    setSelectedInsects(e.value);
    const insectIds = selectedIds.join(',');
    
    getProducts(1, insectIds);
  };
  
  const [insectsData, setinsectsData] = useState([]);

  async function getProductsData() {
    try {
      const langChange = localStorage.getItem('langChange');
      const headers = {
        token: localStorage.getItem('userToken'),
        lang: langChange || 'en' // set default language to 'en' if langChange is not set
      };

      // add support for additional languages
      if (langChange === 'de') {
        headers.lang = 'de';
      } else if (langChange === 'ar') {
        headers.lang = 'ar';
      }

      const response = await axios.get('https://api.insekten-scanner.com/api/dashboard/insects/get_all', { headers });
      const data = response.data;
      setinsectsData(data.data);

    } catch (error) {
      console.error(error);
    }
  }


  async function searchProducts(e, page) {
    if (e.target.value) {

      let { data } = await axios.get(`https://api.insekten-scanner.com/api/dashboard/tables_search/products/${e.target.value}?page=${page}`, {
        headers: {
          token: localStorage.getItem('userToken')


        }
      })
      setProducts(data.data.products);
      //console.log(data);
    }
    else {
      getProducts();
    }

  }


  const [currentPage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  let cancelTokenSource = axios.CancelToken.source();

const onPageChange = (event) => {
  const newPage = event.page + 1;
  setPage(newPage);
  setFirst(event.first);
  setRows(event.rows);

  if (cancelTokenSource) {
    cancelTokenSource.cancel('Request canceled due to new page');
  }

  cancelTokenSource = axios.CancelToken.source();

  getProducts(newPage, selectedInsects.map((insect) => insect.id).join(','), cancelTokenSource.token);
};

  function onSelectionChange(event) {
    setSelectedProducts(event.value);
  }

  useEffect(() => {
    const selectedIds = selectedInsects.map((insect) => insect.id);
    const insectIds = selectedIds.join(',');
  
    let requestPage = page;
  
    if (selectedIds.length === 0) {
      requestPage = 1;
    }
  
    getProducts(requestPage, insectIds);
    getProductsData(requestPage);
  }, [page, selectedInsects]);
  
  let emptyProduct = {
    id: null,
    name: '',
  };


  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);
  const dt = useRef(null);
  const dtt = useRef(null);



  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      let _products = [...products];
      let _product = { ...product };

      if (product.id) {
        const index = findIndexById(product.id);

        _products[index] = _product;
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
      } else {
        _product.id = createId();
        _product.image = 'product-placeholder.svg';
        _products.push(_product);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const editProduct = (product) => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/edit-product/${product.id}?page=${currentPage}`);
    //console.log(currentPage);
  };


  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      const payload = {
        product_id: product.id
      };
      // Send a DELETE request to the API endpoint with the product ID to be deleted and the Authorization header with the token value
      await axios.delete(`https://api.insekten-scanner.com/api/dashboard/products/delete`, {
        headers: {
          token: localStorage.getItem('userToken')
        },
        data: payload

      });

      // Update the products list state to remove the product that was deleted
      const updatedProducts = products.filter((val) => val.id !== product.id);
      setProducts(updatedProducts);

      // Reset the product state and hide the delete product dialog
      setProduct(emptyProduct);
      setDeleteProductDialog(false);

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    } catch (error) {
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete product', life: 3000 });
    }
  };
  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };



  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };
  const deleteSelectedProducts = async () => {
    try {
      const payload = {
        product_id: product.id
      };
      // Send a DELETE request to the API endpoint for each selected product with the Authorization header with the token value
      const deletePromises = selectedProducts.map((product) => {
        return axios.delete(`https://api.insekten-scanner.com/api/dashboard/products/delete`, {
          headers: {
            token: localStorage.getItem('userToken')
          },
          data: payload
        });
      });
      await Promise.all(deletePromises);

      // Update the products list state to remove the deleted products
      const updatedProducts = products.filter((val) => !selectedProducts.includes(val));
      setProducts(updatedProducts);

      // Reset the selected products state and hide the delete products dialog
      setSelectedProducts(null);
      setDeleteProductsDialog(false);

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    } catch (error) {
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete products', life: 3000 });
    }
  };

  const onStatusChange = (e) => {
    let _product = { ...product };

    _product['status'] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };



  const imageBodyTemplate = (rowData) => {
    const handleImageClick = () => {
      //console.log('test');
    };

    const image = rowData.images.find((img) => img.type === "front");
    return image ? (
      <div className=" flex justify-content-center border-round">
        <Image src={image.image}
          alt={rowData.name}
          onClick={handleImageClick}

          width='90px' height='100px' preview />
      </div>

    ) : (
      <span>No image</span>
    );
  };

  const addedByBodyTemplate = (rowData) => {
    if (rowData.is_user === 1) {
      return "User";
    } else if (rowData.is_admin === 1) {
      return "Admin";
    } else if (rowData.from_DB === 1) {
      return "Scanned Product ";
    }

  };
  const statusBodyTemplate = (rowData) => {
    let colorClass, label;

    if (rowData.safe === '0') {
      colorClass = 'p-button-warning';
      label = 'Undefined';
    } else if (rowData.safe === '1') {
      colorClass = 'p-button-success';
      label = 'Safe';
    } else if (rowData.safe === '2') {
      colorClass = 'p-button-danger';
      label = 'Unsafe';
    }

    return (
      <Button label={label} className={`p-button-rounded ${colorClass} ststus-btn`} />);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex gap-4'>
          <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} />
          <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />        </div>
      </React.Fragment>
    );
  };


  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className='d-flex align-items-center '>

        <h3 className='p-2 '>{isLang == "en" ? "Total Products:" : " إجمالي المنتجات:"}</h3>
        <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h3" />
      </div>

      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText onChange={searchProducts} type="search" onInput={(e) => searchProducts(e.target.value)} placeholder={isLang == "en" ? "Search..." : "البحث"} />
      </span>
    </div>
  );
  const leftToolbarTemplate = () => {
    return (
      <>

        <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
          <div className=" d-flex flex-wrap gap-3">
            <Link to="/addProduct">
              <Button label={isLang === "en" ? "Add Product" : "إضافة منتج"} icon="pi pi-plus" severity="success" />
            </Link>
            <Button label={isLang === "en" ? "Delete" : "حذف"}
              icon="pi pi-trash" severity="danger" onClick={() => confirmDeleteSelected(selectedProducts)} disabled={!selectedProducts || !selectedProducts.length} />


          </div>
          <div className="p-d-flex p-ai-center">
            <MultiSelect
              optionLabel="name"
              id="insects"
              filter
              placeholder={isLang === "en" ? "Filter by Insects" : "البحث بواسطة الحشرة"}
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              display="chip"
              options={insectsData}
              onChange={handleInsectSelectionChange} 
              value={selectedInsects} />
          </div>
        </div>

      </>
    );
  };


  const productDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "Cancel" : "إلغاء "} icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label={isLang === "en" ? "Save" : " حفظ"} icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );
  const headerTemplate = (
    <div className="p-d-flex p-ai-center">
      <MultiSelect
        optionLabel="name"
        id="insects"
        filter
        placeholder="Select Insects"
        maxSelectedLabels={3}
        className="w-100 md:w-20rem"
        display="chip"
        options={insectsData}
        onChange={handleInsectSelectionChange} // Call handleInsectSelectionChange function here
        value={selectedInsects}
      />
    </div>
  );

  const insectsOptions = insectsData.map((insect) => ({
    label: insect.name,
    value: insect.id
  }));
  return (

    <>





      <div>
        <Toast ref={toast} />
        <div className="card ">



          <span className='bg-danger'>{insectsData?.name}</span>
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

          <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={onSelectionChange}
            dataKey="id" globalFilter={globalFilter} header={header}>
            <Column selectionMode="multiple" className='px-3 ' exportable={true}></Column>
            <Column field="code" header={isLang === "en" ? "Code" : "الكود"} style={{ minWidth: '12rem' }}></Column>
            <Column field="name" header={isLang === "en" ? "Name" : "المنتج"} className='px-3 test' style={{ minWidth: '16rem' }}></Column>
            <Column field="image" header={isLang === "en" ? "Image" : "الصورة"} body={imageBodyTemplate}></Column>

            <Column field="addedBy" header={isLang === "en" ? "Added By" : "مضاف بواسطة"} body={addedByBodyTemplate} className='px-3' style={{ minWidth: '10rem' }}></Column>
            <Column field="status" header={isLang === "en" ? "Status" : "الحالة"} body={statusBodyTemplate} className='px-3' style={{ minWidth: '10rem' }} />
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
          </DataTable>
          <Paginator ref={dtt.current} first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} currentPage={currentPage - 1} />

        </div>


        <Dialog visible={productDialog} style={{ width: '34rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Product Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
          {product.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.image} className="product-image block m-auto pb-3" />}
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} />
            {submitted && !product.name && <small className="p-error">Name is required.</small>}
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Description
            </label>
            <InputTextarea id="description" value={product.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
          </div>



        </Dialog>

        <Dialog visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {product && (
              <span>
                Are you sure you want to delete <b>{product.name}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {product && <span>Are you sure you want to delete the selected products?</span>}
          </div>
        </Dialog>
      </div></>


  );
}

export default (Products);