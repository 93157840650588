import axios from "axios";
import { toast } from "react-hot-toast";


// Validate From handel Login
export const handelLogin = async (values, setLoadEmail, navigate) => {
    const url = 'https://api.insekten-scanner.com/api/dashboard/login';
    try {
        setLoadEmail(true);
        let { data } = await axios.post(url, values).catch(({ response }) => {
            //console.log(response.data);
            if (response.data.message) {
                toast.error(  response.data?.errors?.email[0])
            }
          
            if (response.data.msg) {
                toast.error( response.data.msg)
            }
          
            setLoadEmail(false);
        })
        //console.log(data);
        if (data.status === true) {

            toast.success('Success')
            localStorage.setItem('userToken', data.data.api_token)
            localStorage.setItem('UserName', data.data.name)
            navigate('/')
            setLoadEmail(false);
        } else {
            setLoadEmail(false);

        }
    } catch ({ error }) {
        //console.log(error);
        setLoadEmail(false);

    }


};

export default {
    handelLogin
};
